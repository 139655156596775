import { PlayIcon } from "lucide-react";
import React from "react";
import type { Stream } from "~/types";
import { cn } from "~/util/css";

export function TwitchLiveStreams() {
  const [streams, setStreams] = React.useState<Stream[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    fetch("/api/users/twitch-live-streams")
      .then(
        (res) =>
          res.json() as Promise<{
            mostWatched: Stream[];
            lessWatched: Stream[];
          }>,
      )
      .then((res) => {
        if (res.mostWatched && res.lessWatched) {
          setStreams([...res.mostWatched, ...res.lessWatched]);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <SkeletonItem />;
  }

  return (
    <>
      {streams?.length ? (
        <div className="flex flex-col gap-2">
          <h2 className="font-medium text-[#6637CE]">Streaming now</h2>
          <div className="w-full flex flex-col gap-6">
            {streams.map((stream, index) => (
              <StreamItem
                key={stream.id}
                stream={stream}
                autoplay={index === 0}
              />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

interface StreamItemProps {
  className?: string;
  stream: Stream;
  autoplay?: boolean;
  liveText?: string;
  type?: "livestream" | "vod";
  onClick?: () => void;
}

export function StreamItem(props: StreamItemProps) {
  const {
    autoplay: initialAutoplay = false,
    stream,
    type = "livestream",
    liveText = "Live streaming",
    className,
  } = props;

  const [autoplay, setAutoplay] = React.useState(initialAutoplay);
  const [isHovering, setIsHovering] = React.useState(false);

  const parent =
    process.env.NODE_ENV === "development"
      ? "localhost"
      : window?.location?.hostname;

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  if (!autoplay && !isHovering) {
    return (
      <StreamThumbnail
        stream={stream}
        onClick={() => {
          props.onClick ? props.onClick() : setAutoplay(true);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        liveText={liveText}
      />
    );
  }

  const iframeSrc =
    type === "livestream"
      ? `https://player.twitch.tv/?channel=${stream.user_login}&parent=${parent}&muted=true&autoplay=${autoplay || isHovering}`
      : `https://player.twitch.tv/?video=${stream.id}&parent=${parent}&muted=true&autoplay=${autoplay || isHovering}`;

  return (
    <div
      className={cn(
        "rounded-lg overflow-hidden h-[180px] relative bg-black",
        className,
      )}
      onMouseLeave={handleMouseLeave}
    >
      <iframe
        src={iframeSrc}
        height="100%"
        width="100%"
        allowFullScreen={true}
        title={stream.title}
      />
    </div>
  );
}

interface StreamThumbnailProps {
  stream: Stream;
  liveText?: string;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

function StreamThumbnail(props: StreamThumbnailProps) {
  const { stream, onClick, onMouseEnter, onMouseLeave, liveText = "" } = props;
  const thumbnailUrl = stream.thumbnail_url
    .replace("{width}", "800")
    .replace("{height}", "450");

  return (
    <div
      className="group relative block w-full h-[180px] rounded-lg overflow-hidden bg-[#EEEAFE] shadow-md cursor-pointer"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="absolute z-10 left-0 top-0 w-full h-full bg-black/50 text-white hidden items-center justify-center group-hover:flex">
        <PlayIcon size={30} />
      </div>
      <img
        src={thumbnailUrl}
        alt={stream.title}
        className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
      />
      <div className="absolute bottom-0 left-0 w-full p-2 bg-gradient-to-t from-black to-transparent text-white">
        <h3 className="text-sm font-semibold">{stream.user_name}</h3>
        <p className="text-xs truncate">{stream.title}</p>
        <div className="flex items-center text-xs gap-1">
          <span className="font-semibold">
            {(stream?.viewer_count || stream?.view_count || 0).toLocaleString()}
          </span>
          <span>viewers</span>
          <span className="ml-auto">{stream.language}</span>
        </div>
      </div>
      <div className="absolute top-2 right-2 bg-[#FF2257] text-white rounded-full px-1.5 py-0.5 text-xs uppercase font-medium">
        {liveText}
      </div>
    </div>
  );
}

function SkeletonItem() {
  return (
    <div className="flex flex-col gap-2">
      <div className="font-medium h-4 bg-gray-300 animate-pulse w-1/2" />
      <div className="h-[180px] bg-gray-300 animate-pulse rounded-lg w-full" />
    </div>
  );
}
